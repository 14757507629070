<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="1000"
        :maskClosable="false"
        title="企业成员"
        :body-style="{ padding: '15px 24px' }"
        :footer="null"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <div class="table-tool" style="margin-bottom: 8px">
            <div class="table-operator">
                <a-button @click="addTeamsModal" type="primary">添加企业成员</a-button>
            </div>
        </div>
        <a-table 
            ref="table"
            size="middle" 
            :columns = "columns.filter((col, num) => {
                if (col.show) {
                    return col
                }
            })"
            :dataSource = "dataSource" 
            :pagination = "pagination"
            :loading = "loading"
            @change="handleTableChange"
            :scroll = "{ x: 'max-content'}"
        >   
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'name'">
                    <span>{{ record['name'] }}</span>
                    <a-tag v-if="record['rules'] == '*'" style="margin-left: 8px;" color="green">超级管理员</a-tag>
                    <a-tag v-else-if="record['rules'] == 'manage'" style="margin-left: 8px;" color="green">管理员</a-tag>
                </template>
                <template v-if="column.dataIndex === 'status'">
                    <a-tag v-if="text=='normal'" color="blue">正常</a-tag>
                    <a-tag v-else>禁用</a-tag>
                </template>
                <template v-else-if="column.dataIndex === 'operation'">
                    <span v-if="record['rules'] === '*' || userInfo['rules'] != '*' && record['rules'] === 'manage'" >不可操作</span>
                    <a-button v-else  @click="editModal(record)" type="primary" size="small">编辑</a-button>
                </template>
            </template>
        </a-table>
    </a-modal>

    <!-- 添加企业成员 -->
    <add-teams-modal v-if="addTeamsModalVisible" :visible="addTeamsModalVisible" @cancel="addTeamsModalVisible = false" @refresh="clickReset"></add-teams-modal>
    
    <!-- 编辑企业成员 -->
    <a-modal v-model:visible="editModalVisible" :centered="true" :maskClosable="false" title="编辑企业成员" :confirm-loading="confirmLoading" @ok="clickEdit" style="-webkit-app-region: no-drag">
        <a-form ref="editFormRef" :model="editFormState" :labelCol="{span:4}">
            <a-form-item label="编号" name="id" style="display:none">
                <a-input v-model:value="editFormState.id"/>
            </a-form-item>
            <a-form-item label="姓名" name="name" :rules="[{ required: true, message: '请输入姓名' }]">
                <a-input v-model:value="editFormState.name" placeholder="请输入姓名" allowClear/>
            </a-form-item>
            <a-form-item label="操作权限" name="group_id">
                <a-radio-group v-model:value="editFormState.group_id" >
                    <a-radio :value="3">销售权限</a-radio>
                    <a-radio :value="2">上品权限</a-radio>
                    <a-radio v-if="userInfo['rules'] != 'manage'" :value="4">领导权限</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="账号状态" name="status">
                <a-radio-group v-model:value="editFormState.status" >
                    <a-radio value="normal">正常</a-radio>
                    <a-radio value="hidden">禁用</a-radio>
                </a-radio-group>
            </a-form-item>
        </a-form>
    </a-modal>

    <!-- 升级版本 -->
    <upgrade-vip-modal v-if="upgradeVipVisible" :visible="upgradeVipVisible" @cancel="upgradeVipVisible = false" ></upgrade-vip-modal>
</template>
<script>
    import {defineComponent,ref,computed} from 'vue';

    import useUser from '@/stores/user';

    import { usePagination } from 'vue-request';
    import { getUserList,addUserCheck,editUser} from '@/api/user';

    import addTeamsModal from '@/components/teams/addTeamsModal';
    import upgradeVipModal from '@/components/upgradeVipModal';

    import { message } from 'ant-design-vue';
    
    export default defineComponent({
        components: {
            addTeamsModal,
            upgradeVipModal
        },
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(){

            const userStore = useUser();
            const userInfo = computed(() => userStore.userInfo);            

            const clickReset = () => {
                run({
                    current:1,
                    pageSize:pageSize.value,
                })
            }

            //table
            const columns = [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '手机号',
                    dataIndex: 'mobile',
                    align: 'left',
                    show: true,
                },
                {
                    title: '操作权限',
                    dataIndex: 'auth_group_name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '账号状态',
                    dataIndex: 'status',
                    align: 'left',
                    show: true,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    align: 'left',
                    fixed: 'right',
                    show: true,
                },
            ];

            const queryData = params => {
                return getUserList(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => (
                    {
                    total: data.value ? data.value.totalCount : '',
                    current: current.value,
                    pageSize: pageSize.value,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                    showSizeChanger:true,
                    }
                )
            );

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    current: pag.current,
                    pageSize: pag.pageSize,
                })
            }

            const confirmLoading = ref(false);

            //添加企业成员
            const addTeamsModalVisible = ref(false);
            const addTeamsModal = () => {
                addUserCheck().then((res) => {
                    if(res.status == 1){
                        if(res.result.is_upgrade == 1){
                            if(userInfo.value.rules === '*' || userInfo.value.rules === 'manage'){
                                upgradeVipVisible.value = true;
                            }else{
                                message.error('请联系管理员升级企业标准版');
                            }
                        }else{
                            addTeamsModalVisible.value = true;
                        }
                    }else{
                        message.error(res.message); 
                    }
                })
            }

            //编辑
            const editFormRef = ref();
            const editModalVisible = ref(false);
            const editFormState = ref();
            const editModal = (record) => {
                editModalVisible.value = true;
                if(editFormRef.value !== undefined) editFormRef.value.resetFields();
                editFormState.value ={
                    id: record.user_id,
                    name:record.name,
                    group_id:record.group_id,
                    status:record.status,
                }
            }

            const clickEdit = () => {
                editFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;
                    editUser(values).then((res) => {
                        if(res.status == 1){
                            editModalVisible.value = false;

                            message.success(res.message);
                            
                            refresh();
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                        
                    })
                }).catch(info => {
                
                });
            }

            //升级版本
            const upgradeVipVisible = ref(false);

            return {
                userInfo,

                clickReset,

                columns,
                dataSource,

                pagination,
                loading,
                handleTableChange,

                confirmLoading,

                addTeamsModalVisible,
                addTeamsModal,

                editFormRef,
                editModalVisible,
                editFormState,
                editModal,
                clickEdit,

                upgradeVipVisible,
            }
        }
    })
</script>